import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">


<path d="M854 2085 c-8 -8 -8 -16 1 -28 11 -16 13 -16 28 1 15 16 15 19 -1 28
-13 8 -20 8 -28 -1z"/>
<path d="M983 1967 c-7 -15 -21 -27 -30 -28 -15 0 -14 -2 4 -11 13 -6 26 -20
29 -32 7 -20 7 -20 15 2 5 12 19 26 31 31 22 8 21 8 -4 14 -17 5 -28 15 -30
29 -3 21 -4 21 -15 -5z"/>
<path d="M615 1964 c-187 -30 -373 -156 -441 -300 -94 -197 20 -363 269 -389
46 -4 67 -3 67 4 0 6 -10 11 -22 11 -29 0 -123 44 -167 77 -42 33 -83 119 -83
178 0 218 293 427 561 401 46 -5 51 -4 26 4 -35 12 -167 20 -210 14z m-415
-384 c0 -22 -4 -40 -10 -40 -11 0 -14 63 -3 73 11 12 13 8 13 -33z m3 -62 c19
16 20 16 8 -7 -11 -20 -10 -27 16 -54 15 -17 20 -25 10 -17 -16 12 -20 12 -28
-1 -8 -10 -9 -9 -4 8 4 16 1 23 -15 28 -12 4 -19 9 -16 13 3 3 -2 15 -11 26
-17 20 -17 21 2 4 18 -17 20 -17 38 0z"/>
<path d="M700 1914 c-104 -20 -210 -76 -282 -147 -73 -73 -77 -98 -5 -29 98
92 216 137 357 136 83 0 102 -4 153 -28 35 -17 68 -42 84 -64 42 -57 44 -59
32 -22 -33 94 -118 149 -241 155 -40 2 -84 1 -98 -1z"/>
<path d="M350 1520 l0 -80 45 0 c33 0 45 4 45 15 0 10 -10 15 -30 15 -24 0
-30 4 -30 21 0 18 4 20 25 15 21 -5 25 -3 25 14 0 16 -5 21 -22 18 -15 -2 -24
3 -26 15 -3 13 3 17 27 17 21 0 31 5 31 15 0 11 -12 15 -45 15 l-45 0 0 -80z"/>
<path d="M460 1558 c0 -24 -3 -60 -6 -80 -6 -34 -4 -38 14 -38 18 0 21 7 24
48 l4 47 14 -47 c8 -27 19 -48 26 -48 6 0 17 20 24 45 12 42 28 62 27 33 -2
-54 4 -78 19 -78 14 0 16 8 10 53 -3 28 -6 65 -6 80 0 24 -4 28 -22 25 -16 -2
-25 -13 -33 -40 -15 -55 -23 -56 -37 -4 -10 38 -16 46 -35 46 -20 0 -23 -5
-23 -42z"/>
<path d="M640 1520 c0 -64 3 -80 15 -80 12 0 15 16 15 80 0 64 -3 80 -15 80
-12 0 -15 -16 -15 -80z"/>
<path d="M695 1520 c0 -70 2 -80 18 -80 14 0 17 9 17 53 l0 52 31 -52 c20 -35
37 -53 50 -53 17 0 19 8 19 80 0 75 -1 80 -21 80 -20 0 -21 -4 -15 -52 l6 -53
-19 25 c-11 14 -26 37 -32 53 -7 17 -19 27 -33 27 -20 0 -21 -5 -21 -80z"/>
<path d="M850 1520 l0 -80 45 0 c51 0 66 26 18 32 -17 2 -28 9 -28 18 0 10 8
14 28 12 22 -2 27 1 27 18 0 17 -5 21 -25 18 -13 -2 -24 0 -25 5 -1 23 2 27
25 27 16 0 25 6 25 15 0 11 -12 15 -45 15 l-45 0 0 -80z"/>
<path d="M960 1520 c0 -75 1 -80 21 -80 20 0 21 3 15 52 -6 43 -5 50 7 40 8
-7 22 -30 32 -52 13 -28 24 -40 39 -40 20 0 21 4 18 80 -3 61 -7 80 -18 80
-10 0 -14 -14 -14 -52 l0 -53 -31 53 c-20 34 -37 52 -50 52 -17 0 -19 -8 -19
-80z"/>
<path d="M1110 1585 c0 -8 8 -15 19 -15 17 0 18 -6 13 -65 -4 -61 -4 -65 17
-65 19 0 21 5 21 65 0 58 2 65 20 65 11 0 20 7 20 15 0 12 -13 15 -55 15 -42
0 -55 -3 -55 -15z"/>
<path d="M1230 1520 l0 -80 34 0 c50 0 86 33 86 79 0 49 -32 81 -83 81 l-37 0
0 -80z m74 34 c21 -20 20 -43 -1 -66 -27 -30 -38 -21 -38 32 0 53 11 62 39 34z"/>
<path d="M1384 1520 c-29 -74 -30 -80 -12 -80 10 0 21 7 24 15 4 9 19 15 40
15 24 0 34 -5 34 -15 0 -8 9 -15 20 -15 11 0 20 1 20 3 0 1 -13 36 -29 77 -40
105 -56 105 -97 0z m63 -16 c-3 -3 -12 -4 -20 -2 -11 2 -12 9 -5 28 l10 25 11
-23 c5 -12 8 -25 4 -28z"/>
<path d="M1510 1585 c0 -9 9 -15 21 -15 19 0 20 -5 17 -65 -3 -57 -1 -65 15
-65 14 0 17 10 17 65 0 58 2 65 20 65 11 0 20 7 20 15 0 12 -13 15 -55 15 -42
0 -55 -3 -55 -15z"/>
<path d="M1644 1520 l-31 -80 23 0 c15 0 24 6 24 15 0 10 11 15 35 15 24 0 35
-5 35 -15 0 -8 9 -15 20 -15 11 0 20 1 20 3 0 1 -13 36 -29 77 -40 105 -56
105 -97 0z m66 -7 c0 -7 -7 -13 -15 -13 -16 0 -19 9 -9 35 5 13 8 14 15 3 5
-7 9 -19 9 -25z"/>
<path d="M1780 1520 c0 -74 1 -80 21 -80 18 0 20 4 14 30 -6 27 -4 30 18 30
46 0 69 39 47 81 -8 14 -21 19 -55 19 l-45 0 0 -80z m70 30 c0 -13 -7 -20 -20
-20 -13 0 -20 7 -20 20 0 13 7 20 20 20 13 0 20 -7 20 -20z"/>
<path d="M1905 1520 l0 -80 48 0 c35 0 47 4 47 15 0 10 -10 15 -30 15 l-30 0
0 65 c0 55 -3 65 -17 65 -16 0 -18 -10 -18 -80z"/>
<path d="M2010 1537 c0 -71 17 -97 63 -97 43 0 57 24 57 96 0 50 -3 64 -15 64
-11 0 -15 -13 -15 -54 0 -35 -5 -58 -15 -66 -24 -20 -35 -2 -35 61 0 52 -2 59
-20 59 -18 0 -20 -7 -20 -63z"/>
<path d="M2172 1577 c-28 -30 -28 -76 -1 -111 37 -47 112 -26 121 34 4 29 3
30 -34 30 -21 0 -38 -4 -38 -10 0 -5 9 -10 21 -10 24 0 21 -18 -5 -34 -14 -9
-21 -6 -37 13 -32 39 -7 87 39 76 31 -7 46 3 31 21 -18 22 -72 17 -97 -9z"/>
<path d="M2395 1576 c-36 -36 -33 -77 6 -111 37 -30 92 -32 97 -2 3 13 -4 17
-30 17 -40 0 -58 15 -58 47 0 28 31 46 66 37 22 -6 25 -4 22 12 -6 31 -72 31
-103 0z"/>
<path d="M2549 1590 c-9 -5 -22 -23 -29 -40 -32 -77 56 -144 121 -91 18 14 24
29 24 61 0 32 -6 47 -24 61 -25 20 -66 24 -92 9z m69 -32 c19 -19 15 -66 -7
-78 -25 -13 -27 -13 -45 6 -21 21 -20 61 2 73 23 14 36 13 50 -1z"/>
<path d="M2690 1567 c0 -19 -3 -55 -6 -81 -6 -37 -4 -46 9 -46 10 0 17 15 22
48 l8 47 15 -47 c9 -27 21 -48 28 -48 6 0 18 21 25 48 l13 47 4 -47 c3 -41 6
-48 24 -48 19 0 20 4 14 46 -3 26 -6 62 -6 80 0 27 -4 34 -20 34 -13 0 -24
-10 -30 -27 -26 -70 -26 -71 -42 -23 -10 33 -21 46 -36 48 -19 3 -22 -1 -22
-31z"/>
<path d="M2312 1463 c5 -26 38 -29 38 -4 0 15 -6 21 -21 21 -14 0 -19 -5 -17
-17z"/>
<path d="M1150 1308 c0 -25 3 -29 10 -18 5 8 10 10 10 4 0 -6 7 -11 15 -11 10
0 15 10 15 31 0 28 -1 29 -15 10 -13 -17 -14 -17 -15 -1 0 9 -4 17 -10 17 -5
0 -10 -15 -10 -32z"/>
<path d="M1234 1325 c-8 -21 3 -48 19 -43 19 7 22 58 2 58 -8 0 -18 -7 -21
-15z"/>
<path d="M1295 1330 c-3 -6 1 -13 9 -16 9 -3 16 -10 16 -15 0 -4 -7 -5 -17 -2
-11 4 -14 3 -9 -5 14 -24 35 -13 34 18 -1 29 -20 41 -33 20z"/>
<path d="M1460 1321 c-15 -29 -13 -36 14 -37 22 -2 23 0 14 27 -12 34 -14 35
-28 10z"/>
<path d="M1570 1310 c0 -16 5 -30 10 -30 9 0 12 14 9 36 0 4 5 11 13 15 9 6 7
9 -9 9 -19 0 -23 -6 -23 -30z"/>
<path d="M1634 1325 c-8 -21 3 -48 19 -43 19 7 22 58 2 58 -8 0 -18 -7 -21
-15z"/>
<path d="M1700 1308 c0 -25 3 -29 10 -18 6 10 10 11 10 3 0 -7 4 -13 8 -13 16
0 5 55 -11 58 -13 3 -17 -4 -17 -30z"/>
<path d="M1760 1310 c0 -25 4 -31 18 -28 11 2 17 12 17 28 0 16 -6 26 -17 28
-14 3 -18 -3 -18 -28z"/>
<path d="M1826 1318 c-8 -33 -8 -34 14 -34 20 0 25 12 14 40 -9 23 -21 20 -28
-6z"/>
<path d="M1890 1310 c0 -20 5 -30 15 -30 10 0 15 10 15 30 0 20 -5 30 -15 30
-10 0 -15 -10 -15 -30z"/>
<path d="M2002 1313 c2 -19 10 -29 23 -31 17 -3 17 -1 5 7 -13 10 -13 12 0 21
13 9 13 10 0 10 -13 0 -13 1 0 10 12 8 10 10 -8 10 -20 0 -23 -4 -20 -27z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
